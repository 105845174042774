/** 
 * TASKS-GENERATOR.WIDGET
 * Generate tasks with Open AI
 */

import ListButton from "@/components/list-button"
import Space from "@/components/space"
import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import { Socket } from "socket.io-client"
import { SocketCreate, SocketEmit } from "@/utils/socketio.utils"
import { toast } from "react-toastify"
import { Topic } from "@/redux/topic.types"
import Modal from "@/components/modal"
import Chip from "@/components/chip"
import { faCheck, faCirclePlus } from "@fortawesome/free-solid-svg-icons"
import Link from "@/components/link"
import { FadeIn } from "@/utils/animations.utils"
import TasksCustomPrompt from "./tasks-custom-prompt"

interface StateProps{
  _session : Session
}

interface OwnProps{
  onSelect: Function
  selectedTopic: Topic
  onStatusUpdate?: Function
}

type Props = StateProps & OwnProps & WithTranslation

export type Idea = { title: string, text: string, selected?: boolean }

const REVEAL_INTERVAL: number = 700

function TasksGeneratorWidget(props: Props) {
  const { t } = props

  const [ideas, setIdeas] = useState<Idea[]>([])
  const [activeIdeaIndex, setActiveIdeaIndex] = useState<number | undefined>(undefined)
  const [countReveal, setCountReveal] = useState<number>(1)
  const [isOwlHovered, setIsOwlHovered] = useState<boolean>(false)
  const [isCustomMode, setIsCustomMode] = useState<boolean>(false)
  const [preprompt, setPreprompt] = useState<string>("")
  const [prompt, setPrompt] = useState<string>("")

  useEffect(() => {

    updateStatus(true)
    setCountReveal(0)
    setIdeas([])

    const socket: Socket = SocketCreate()

    let answer: any

    SocketEmit(
      socket,
      "task",
      { questionLabel: props.selectedTopic.Questions[0].label },
      props._session.language,
      undefined,
      (ans: any) => answer = ans,
      (err: any) => {toast(err, {type: "error"}); updateStatus(false)},
      () => {setIdeas(JSON.parse(answer).ideas); updateStatus(false)}
    )

    return () => { socket.disconnect() }

  }, [
    props.selectedTopic.Questions[0].label
  ])

  useEffect(() => {
    if(prompt.length > 0){
      updateStatus(true)
      setCountReveal(0)
      setIdeas([])

      const socket: Socket = SocketCreate()

      let answer: any

      SocketEmit(
        socket,
        "task-custom",
        { prompt, preprompt },
        props._session.language,
        undefined,
        (ans: any) => answer = ans,
        (err: any) => {toast(err, {type: "error"}); updateStatus(false)},
        () => {setIdeas(JSON.parse(answer).ideas); updateStatus(false)}
      )

      return () => { socket.disconnect() }
    }
  }, [
    prompt,
    preprompt
  ])

  useEffect(() => {
    if (ideas.length > 0 && countReveal <= ideas.length) {

      const interval: NodeJS.Timeout = setInterval(() => {
        setCountReveal((prev: number) => prev + 1);
      }, REVEAL_INTERVAL)

      return () => clearInterval(interval);
    }
  }, [
    ideas
  ])

  function select(index: number){
    const newIdeas: Idea[] = ideas.map((idea: Idea, i: number) => i === index ? {...idea, selected: !idea.selected} : idea)
    setIdeas(newIdeas)
    props.onSelect(newIdeas.filter((idea: Idea) => idea.selected))
  }

  function reset(){
    setIdeas(ideas.map((idea: Idea) => ({...idea, selected: false})))
    props.onSelect([])
  }

  function updateStatus(loading: boolean){
    if(props.onStatusUpdate){
      props.onStatusUpdate(loading)
    }
  }

  return (
    <>

      { activeIdeaIndex &&
      <Modal
        onClose={() => setActiveIdeaIndex(undefined)}
        isCloseButtonVisible
      >

        <div className="flex flex-dcol">

          <div style={{ 
            fontSize: 12,
            color: props.selectedTopic?.Axis?.color 
          }}>

            <b>
              {props.selectedTopic?.label}
            </b>

            { props.selectedTopic?.Axis &&
            <div>
              {props.selectedTopic?.Axis?.label}
            </div>
            }

            <div style={{ height : 6 }}/>

          </div>

          <div className="height-20" />

          <div>
            <p><b>{ideas[activeIdeaIndex - 1].title}</b></p>
          </div>

          <div>
            <p>{ideas[activeIdeaIndex - 1].text}</p>
          </div>

          <div className="height-20" />

          <Space />

          <div className="flex" style={{padding: "10px 0"}}>

            <Space />

            <Chip
              color={ideas[activeIdeaIndex - 1].selected ? props._session.accountColors.active : undefined}
              onClick={() => select(activeIdeaIndex - 1)}
              icon={ideas[activeIdeaIndex - 1].selected ? faCheck : faCirclePlus}
            >
              {t(ideas[activeIdeaIndex - 1].selected ? "tasks_added_ap" : "tasks_add_ap")}
            </Chip>

          </div>

        </div>

      </Modal>
      }

      {( isCustomMode && props._session.supervisorLevel > 1 ) ?
      <TasksCustomPrompt
        questionLabel={props.selectedTopic.Questions[0].label}
        language={"fr"}
        onCancel={() => setIsCustomMode(false)}
        onSubmit={
          (preprompt: string, prompt: string) => {
            setPreprompt(preprompt);
            setPrompt(prompt);
            setIsCustomMode(false)
          }
        }
      />
      :
      <>
        {( ideas.length > 0 && !isCustomMode && props._session.supervisorLevel > 1 ) ?
        <div className="rel">
          <div
            title={t("tasks_set_custom_prompt")}
            className="_hover abs"
            style={{
              top: -200,
              right: 0,
              height: 600,
              width: 150,
              backgroundColor: "white",
              opacity: isOwlHovered ? 0.7 : 0
            }}
            onFocus={() => {}}
            onMouseOver={() => setIsOwlHovered(true)}
            onMouseLeave={() => setIsOwlHovered(false)}
            onClick={() => { setIsCustomMode(true); setIsOwlHovered(false)}}
          />
        </div>
        :
        <div />
        }
        
        <div 
          className="flex flex-wrap"
          style={{ 
            overflowY: "auto",
            marginLeft: -6,
            minHeight: 180
          }}
        >

          { ideas.slice(0, countReveal).map((idea: Idea, i: number) =>
          <FadeIn key={i} style={{zIndex: 2}}>
            <div
              className="_hover flex flex-dcol"
              style={{
                margin: 6,
                width: 200,
                height: 160,
                padding: "10px 20px",
                borderRadius: 8,
                border: "1px solid rgb(220 220 220)",
                backgroundColor: "white",
                boxShadow: "2px 2px 6px #cfcfcf"
              }}
              onClick={() => setActiveIdeaIndex(i + 1)}
            >
            
              <div><b>{idea.title}</b></div>

              <div className="height-10" />

              <div className="rel" style={{ overflow: "hidden", textOverflow: "ellipsis", fontSize: 12 }}>
              
                {idea.text}
              
                <div
                  className="abs"
                  style={{
                    height: 70,
                    width: "100%",
                    zIndex: 3,
                    bottom: 0,
                    marginTop: -20,
                    background: "linear-gradient(transparent, rgba(255, 255, 255))"
                  }}
                />
              </div>

              <Space/>

              <div className="flex" style={{marginBottom: 10, zIndex: 4}}>
                <Space />

                <div title={t(idea.selected ? "tasks_added_ap" : "tasks_add_ap")} >
                  <ListButton
                    isPrimary
                    isColorActive={idea.selected}
                    isFat
                    onClick={() => select(i)}
                    icon={idea.selected ? faCheck : faCirclePlus}
                  />
                </div>
              </div>

            </div>
          </FadeIn>
          )}
        </div>
      </>
      }

      <div className="height-20" />

      { ideas.some((idea: Idea) => idea.selected) ?
      <Link onClick={reset} isWithoutMargin>
        {t("populations_reset")}
      </Link>
      :
      <div>{" "}</div>
      }

    </>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(TasksGeneratorWidget))