/**
 * DASHBOARD-REPARTITION-CHART.WIDGET
 * Chart for the repartition
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useEffect, useState } from "react"
import { FilterState } from "@/redux/filter.types"
import { UserState } from "@/redux/user.types"
import { store } from "@/index"
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types"
import { fetchQuestions, fetchRepartition } from "@/redux/_archive.actions"
import { SurveyState } from "@/redux/survey.types"
import { generateRepartition } from "@/redux/_generate.actions"
import { Session } from "@/redux/_session.types"
import PageLoader from "@/components/page-loader"
import DashboardRepartitionBarsWidget from "./dashboard-repartition-bars.widget"

interface StateProps extends WithTranslation {
  _session: Session
  filter: FilterState
  survey: SurveyState
  user: UserState
}

interface OwnProps {
  questionNpsAid?: number
}

type Props = StateProps & OwnProps

function DashboardRepartitionChartWidget(props: Props) {
  const { t } = props

  //State
  //Values to display
  //Max value (for height)
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState([])
  const [showData, setShowData] = useState(true)

  //Load survey data on load or change filters
  //Set values and value max
  useEffect(() => {
    async function load() {
      const response: any = props.survey.active.randomData
        ? await store.dispatch(generateRepartition(props.filter.dashboard))
        : await store.dispatch(
            fetchRepartition(
              props.filter.dashboard,
              props._session.dashboardDisplaySettings.dashboardDisplayMode === "satisfaction",
              null,
              null
            )
          )

      if (!response.error) {
        //If INITIAL
        if (props.survey.active.id === "a59367c6-8549-49a7-868f-7601269f32d6") {
          response.values = [1, 4, 22, 96, 163, 178, 125, 55, 22, 1]
        } else if (props.survey.active.id === "70aca62c-c5a8-4095-a113-ccbff5413d55") {
          response.values = [0, 0, 7, 15, 41, 59, 50, 41, 10, 4]
        }

        setValues(response.values)
        if (response.values.length) {
          setShowData(true)
        } else {
          setShowData(false)
        }
      } else {
        setShowData(false)
      }

      setIsLoading(false)
    }

    async function loadQuestion() {
      const response: any = await store.dispatch(fetchQuestions(props.filter.dashboard, null))

      if (!response.error) {
        const question = response.find((x) => x.aid === props.questionNpsAid)
        if (question) {
          setValues(question.repartition)
        }
      }

      setIsLoading(false)
    }

    if (props.questionNpsAid) {
      loadQuestion()
    } else {
      load()
    }
  }, [
    props.survey.active.id,
    props.filter.dashboard,
    props.questionNpsAid,
    props.survey.active.randomData,
    props._session.dashboardDisplaySettings.dashboardDisplayMode
  ])

  return (
    <div className="repartition">
      {isLoading ? (
        <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED} />
      ) : showData ? (
        <DashboardRepartitionBarsWidget
          values={values.length > 1 ? values : []}
          questionNpsAid={props.questionNpsAid}
          displaySatisfaction={props._session.dashboardDisplaySettings.dashboardDisplayMode === "satisfaction"}
          showCeils
        />
      ) : (
        <p className="grey-t">{t("survey_no_results")}</p>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
  survey: state.survey,
  user: state.user
})

export default connect(mapStateToProps)(withTranslation()(DashboardRepartitionChartWidget))
