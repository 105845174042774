/** 
 * TASKS-CUSTOM-PROMPT.WIDGET
 * Custom prompt for tasks
 */

import Button from "@/components/button"
import Link from "@/components/link"
import { useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps{
  questionLabel: string
  language?: string
  onCancel?: Function
  onSubmit: Function
}

type Props = OwnProps & WithTranslation

function TasksCustomPromptWidget(props: Props) {
  const { t } = props

  const defaultPreprompt: string = props.language === "fr" ?
  `Vous êtes consultant en management. Lorsqu'un employé vous fait part des problèmes qu'il rencontre dans son entreprise, vous lui proposez un audit détaillé des solutions possibles pour résoudre le problème. Utilisez votre expertise pour être le plus pertinent possible.
  Répondez dans la même langue que la question posée.
  Répondez au format JSON.
  Pour chaque idée/solution, merci de respecter le format suivant:
  {
    ideas: [
      {
        title: "Titre de l'idée en quelques mots",
        text: "Description détaillée de la tache en plan d'action"
      },
      {
        title: "Deuxième idée",
        text: "Voici en quoi consiste la deuxième idée..."
      }
    ]
  }
  ` : `You're a management consultant. When an employee tells you about the problems they're experiencing in their company, you give them a detailed audit of possible ways of solving the problem. Use your expertise to be as relevant as possible.
  Answer in the same language as the question asked : if the question is in french, please answer in french.
  Answer in JSON format.
  For each idea/solution please respect the format as follows:
  {
    ideas: [
      {
        title: "Title of the idea in few words",
        text: "Detailled description of the action plan task"
      },
      {
        title: "Idea number 2",
        text: "Here's what is my second idea..."
      }
    ]
  }
  `

  const defaultPrompt: string = props.language === "fr" ?
  `Une enquête a été diffusée dans mon entreprise.
  La question « ${props.questionLabel} » a reçu beaucoup de réponses négatives.
  Pouvez-vous me donner des pistes d'action pour améliorer la situation de l'entreprise ?
  ` : `A survey was distributed in my company.
  The question « ${props.questionLabel} » received many negative responses.
  Can you give me some ideas for action to improve the situation in the company?
  `

  const [prompt, setPrompt] = useState<string>(defaultPrompt)

  return (
    <div style={{ width: 600 }}>
      { props.onCancel &&
      <div style={{ margin: "10px 0"}}>
        <Link 
          isWithoutMargin
          onClick={() => props.onCancel ? props.onCancel() : {}}
        >
          {t("utils_cancel")}
        </Link>
      </div>
      }

      <div>
        <label htmlFor="prompt"><b>{t("prompt")}</b>{" (instructions pour ChatGPT)"}</label>
      </div>

      <textarea
        id="prompt"
        name={t("prompt")}
        placeholder={t("prompt")}
        className="text-area"
        style={{
          height: 120,
        }}
        onChange={(e: any) => setPrompt(e.target.value)}
        value={prompt}
      />

      <div>
        <Button
          isWithBorder
          isFullWidth
          className={prompt.length > 0 ? "secondary" : "light"}
          onClick={prompt.length > 0 ? () => props.onSubmit(defaultPreprompt, prompt) : undefined}
        >
          {t("Appliquer le nouveau prompt")}
        </Button>
      </div>

    </div>
  )

}

export default withTranslation()(TasksCustomPromptWidget)