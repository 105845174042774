/**
 * REPORT-SCREENSHOT-IMAGE.WIDGET.TSX
 * Specific thumbnail for widget
 */
import { PageContentScreenshotType } from "@/redux/page.types"

interface OwnProps {
  type: PageContentScreenshotType | null
  isFullWidth?: boolean
}

function ReportScreenshotImageWidget(props: OwnProps) {
  return <img width="85%" alt={"preview_" + props.type} src={require("@/assets/report/" + props.type + ".png")} />
}

export default ReportScreenshotImageWidget
