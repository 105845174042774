/**
 * DASHBOARD-PARTICIPATION-RATES.WIDGET
 * Display participation rates over milestones
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { SurveyState } from "@/redux/survey.types"
import { Session } from "@/redux/_session.types"
import { useEffect, useState } from "react"
import { DateType, SurveyInvitation } from "./dashboard-participation.widget"
import Dropdown from "@/components/dropdown"
import initFilters from "@/utils/init-filters.utils"
import { store } from "@/index"
import { sessionEditAccountOptions } from "@/redux/_session.actions"
import getFilterName from "@/utils/get-filter-name.utils"
import { groupBy, orderBy } from "lodash"
import ProgressBar from "@/components/progress-bar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { formatDate } from "@/utils/format-date.utils"
import Space from "@/components/space"
import getUserFilterDates, { UserFilterDates } from "@/utils/get-user-filter-dates.utils"
import ReactTooltip from "react-tooltip"
import SortMethodsWidget, { SortMethod } from "./sort-methods.widget"

interface StateProps extends WithTranslation {
  _session: Session
  survey: SurveyState
}

interface OwnProps {
  invitations: SurveyInvitation[]
  selectedDateType: DateType
  hideControls?: boolean
  filterName?: string
}

type Props = StateProps & OwnProps

type DataType = {
  key: string | null
  total: number
  dateClicked: number
  dateStarted: number
  dateDone: number
}

type DateItem = {
  id: DateType
  color: string
}

function DashboardParticipationPopulationsWidget(props: Props) {
  const { t } = props

  const [dateItems] = useState<DateItem[]>(initDateItems())

  const rangeMin: number = new Date(props.survey.active.dateStart).getTime()
  const rangeMax: number = props.survey.active.dateEnd ? new Date(props.survey.active.dateEnd).getTime() : Date.now()
  const rangeStep: number = 24 * 60 * 60 * 1000 // 1 day
  const datePopulations: UserFilterDates[] =
    props._session.accountOptions.heatmapFilterName === "birth_date"
      ? getUserFilterDates("BIRTH_DATE", props.survey.active.dateStart)
      : getUserFilterDates("COMPANY_WELCOME_DATE", props.survey.active.dateStart)

  const [sort, setSort] = useState<SortMethod | undefined>(undefined)

  const [order, setOrder] = useState<"asc" | "desc">("asc")

  const [refDate, setRefDate] = useState<number>(rangeMax)

  const [filterName, setFilterName] = useState<string | null>(
    props.filterName ? props.filterName : props._session.accountOptions.heatmapFilterName
  )

  useEffect(() => {
    if (props.filterName) {
      setFilterName(props.filterName)
    } else {
      setFilterName(props._session.accountOptions.heatmapFilterName)
    }
  }, [props.filterName, props._session.accountOptions.heatmapFilterName])

  //Hide date items if controls not visibles
  function initDateItems() {
    const dateItems: DateItem[] = [{ id: "dateDone", color: props._session.accountColors.brandPrimary }]
    if (props.hideControls) {
      return dateItems
    } else {
      const otherDateItems: DateItem[] = [
        { id: "dateClicked", color: "#b8b8b8" },
        { id: "dateStarted", color: "#888888" }
      ]
      return otherDateItems.concat(dateItems)
    }
  }

  function getAmount(invitations: SurveyInvitation[], dateType: DateType): number {
    return invitations.filter((x: SurveyInvitation) => x[dateType] && x[dateType]! < refDate).length
  }

  function formatData(): DataType[] {
    return filterName === "company"
      ? [
          {
            key: t("user_company_alt"),
            total: props.invitations.length,
            dateClicked: getAmount(props.invitations, "dateClicked"),
            dateStarted: getAmount(props.invitations, "dateStarted"),
            dateDone: getAmount(props.invitations, "dateDone")
          }
        ]
      : Object.entries(groupBy(props.invitations, "name"))
          .filter((item: any) => item[1].length >= props._session.participationMin)
          .map(([key, values]) => ({
            key: key === "null" || key === "undefined" ? null : key,
            total: values.length,
            dateClicked: getAmount(values, "dateClicked"),
            dateStarted: getAmount(values, "dateStarted"),
            dateDone: getAmount(values, "dateDone")
          }))
  }

  function getSort(): DataType[] {
    switch (sort) {
      case "alphabetical":
        return orderBy(formatData(), (item: DataType) => item.key, [order])
      case "total":
        return orderBy(formatData(), (item: DataType) => item.total, [order])
      case "participation":
        return orderBy(formatData(), (item: DataType) => item[props.selectedDateType], [order])
      case "ratio":
        return orderBy(formatData(), (item: DataType) => item[props.selectedDateType] / item.total, [order])
      default:
        return formatData()
    }
  }

  function getRowName(key: string | null): { name: string; description?: string } {
    if (!key) {
      const name: string = t("utils_others")
      return { name }
    } else if (filterName === "birth_date" || filterName === "company_welcome_date") {
      const { name, description } = key.match(/^-?\d+$/)
        ? datePopulations[parseInt(key, 10)]
        : { name: key, description: key }
      return { name, description }
    } else if (filterName === "gender") {
      const name: string = t("user_gender_" + key)
      return { name }
    } else {
      const name = key
      return { name }
    }
  }

  function display(): JSX.Element[] {
    return (
      !filterName || filterName === "company" ? getSort() : getSort().filter((item: DataType) => item.key !== null)
    ).map((item: DataType) => {
      const { name, description } = getRowName(item.key)
      return (
        <div key={item.key} className="flex rel">
          <div className="flex2 flex">
            <div className="flex flex-dcol">
              <Space />
              {name}
              <Space />
            </div>

            <div className="width-10" />

            {description && (
              <div className="flex flex-dcol">
                <Space />
                <FontAwesomeIcon
                  className="_hover"
                  data-tip={description}
                  icon={faInfoCircle}
                  color={"#8C8C8C"}
                  style={{ marginRight: 20 }}
                />
                <Space />
              </div>
            )}
          </div>

          <div className="flex4 flex flex-dcol">
            <Space />
            <ProgressBar
              items={dateItems.map((x: DateItem) => ({
                value: item[x.id],
                text: t("participation_" + x.id.slice(4).toLowerCase() + "_info", { population: `"${name}"` }),
                color: x.color
              }))}
              max={item.total}
            />
          </div>

          <div className="width-20" />

          <div className="flex1 flex flex-dcol">
            <Space />
            {((item[props.selectedDateType] / item.total) * 100).toFixed(0) + "%"}
            <Space />
          </div>
        </div>
      )
    })
  }

  function getTickmarks(nbMax: number) {
    const steps: number = (rangeMax - rangeMin) / rangeStep
    const interval: number = steps > nbMax ? Math.ceil(steps / nbMax) * rangeStep : rangeStep

    return Array.from(Array(steps < nbMax ? Math.ceil(steps) : nbMax).keys()).map((x: number) => {
      const timestamp: number = rangeMin + x * interval
      return <option key={x} value={timestamp} label={formatDate(timestamp, false, false)} />
    })
  }

  return (
    <div>
      {!props.hideControls && (
        <>
          <div className="flex">
            <Dropdown
              active={filterName}
              displayField="name"
              list={initFilters()}
              onSelect={(e: any) => store.dispatch(sessionEditAccountOptions("heatmapFilterName", e.id))}
              data-tip={t("filter")}
              value={filterName ? getFilterName(filterName) : null}
            />

            <div className="width-100" />

            <SortMethodsWidget
              sortMethod={sort}
              order={order}
              onSortClic={(sortMethod: SortMethod) => setSort(sortMethod)}
              onOrderClic={(orderItem: "asc" | "desc") => setOrder(orderItem)}
              hideItems={["numerical"]}
            />

            <div className="width-100" />

            <Space />

            <div className="flex flex-dcol">
              <Space />

              <h3 className="grey-t">
                {t("participation_date", { date: formatDate(new Date(parseInt(refDate + "", 10)), false, false) })}
              </h3>

              <input
                type="range"
                className="range"
                min={rangeMin}
                max={rangeMax}
                step={rangeStep}
                value={refDate}
                style={{ width: "400px" }}
                list="tickmarks"
                onChange={(e: any) => setRefDate(e.target.value)}
              />

              <datalist id="tickmarks">
                {getTickmarks(3)}
                <option value={rangeMax} label={formatDate(rangeMax, false, false)} />
              </datalist>

              <Space />
            </div>
          </div>

          <div className="height-40" />
        </>
      )}

      <div className="flex" style={{ minWidth: 800 }}>
        <div className="flex2 flex flex-dcol grey-t">
          <Space />
          {t("attributes")}
        </div>

        <div className="flex4 flex flex-wrap">
          {
            //empty slice() prevents dateItems from mutating
            dateItems.length > 1 &&
              dateItems
                .slice()
                .reverse()
                .map((x: DateItem) => (
                  <div key={x.id} className="flex">
                    <div
                      style={{
                        height: 20,
                        width: 20,
                        borderRadius: 10,
                        backgroundColor: x.color,
                        margin: "0 10px"
                      }}
                    />
                    <div className="grey-t">{t("participation_" + x.id.slice(4).toLowerCase()) + " "}</div>

                    <div className="flex flex-dcol">
                      <Space />
                      <FontAwesomeIcon
                        className="_hover"
                        data-tip={t("participation_" + x.id.slice(4).toLowerCase() + "_info", { population: "" })}
                        icon={faInfoCircle}
                        color={"#8C8C8C"}
                        style={{ marginRight: 20 }}
                      />
                      <Space />
                    </div>
                  </div>
                ))
          }
        </div>

        <div className="width-20" />

        <div className="flex1 flex flex-dcol grey-t">
          <Space />
          {"% " + t("participation_" + props.selectedDateType.slice(4).toLowerCase())}
        </div>
      </div>

      <div className="height-20" />

      <div className="rel">
        {filterName !== "company" && (
          <div className="abs flex" style={{ height: "100%", width: "100%" }}>
            <div className="flex2" />
            <div className="flex4 flex rel">
              {dateItems.map((x: DateItem, index: number) => {
                const amount: number = getAmount(props.invitations, x.id)
                const total: number = props.invitations.length
                return (
                  <div
                    key={index}
                    data-tip={`${t("participation_" + x.id.slice(4).toLowerCase())} : ${Math.round(
                      (amount / total) * 100
                    )}% (${amount}/${total})`}
                    className="abs"
                    style={{
                      height: "100%",
                      width: 3,
                      backgroundColor: x.color,
                      borderRadius: 3,
                      marginLeft: (amount / total) * 100 + "%"
                    }}
                  />
                )
              })}
            </div>

            <div className="width-20" />
            <Space />
          </div>
        )}

        <div className="height-20" />

        {display()}
      </div>

      <ReactTooltip />
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  survey: state.survey
})

export default connect(mapStateToProps)(withTranslation()(DashboardParticipationPopulationsWidget))
