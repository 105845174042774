/**
 * DASHBOARD-PARTICIPATION.WIDGET
 * Display participation progress
 */

import { connect } from "react-redux"
import { SurveyState } from "@/redux/survey.types"
import { Session } from "@/redux/_session.types"
import Card from "@/components/card"
import { useEffect, useState } from "react"
import DashboardParticipationProgressWidget from "./dashboard-participation-progress.widget"
import PageLoader from "@/components/page-loader"
import { STATUS_LOADING } from "@/redux/_status.types"
import DashboardParticipationPopulationsWidget from "./dashboard-participation-populations.widget"
import { FilterState } from "@/redux/filter.types"
import DashboardParticipationOverviewWidget from "./dashboard-participation-overview.widget"
import { formateParticipation } from "@/utils/formate-participation.utils"
import getHeatmapCeils from "@/utils/get-heatmap-ceils.utils"
import { store } from "@/index"
import { fetchParticipationFull } from "@/redux/_archive.actions"

interface StateProps {
  _session: Session
  filter: FilterState
  survey: SurveyState
}

interface OwnProps {
  isPreview?: boolean
}

type Props = StateProps & OwnProps

export type SurveyInvitation = {
  aid?: any
  name?: string
  dateCreated: number
  dateClicked: number | null
  dateStarted: number | null
  dateDone: number | null
}

export type DateType = "dateClicked" | "dateStarted" | "dateDone"

export type Period = {
  name: string
  duration: number
}

function DashboardParticipationWidget(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [invitations, setInvitations] = useState<SurveyInvitation[]>([])

  useEffect(() => {
    async function loadInvitations() {
      setIsLoading(true)

      const response: any = await store.dispatch(
        fetchParticipationFull(
          props.filter.dashboard,
          props._session.accountOptions.heatmapFilterName,
          getHeatmapCeils(props._session.accountOptions.heatmapFilterName)
        )
      )

      formateParticipation(response)

      setInvitations(response.error ? [] : response)

      setIsLoading(false)
    }

    loadInvitations()
  }, [props.survey.active.id, props._session.accountOptions.heatmapFilterName, props.filter.dashboard])

  return (
    <PageLoader status={isLoading ? STATUS_LOADING : null}>
      <div className="flex">
        <Card>
          <DashboardParticipationOverviewWidget column width={140} />
        </Card>

        <div className="width-40" />

        {props.isPreview ? (
          <DashboardParticipationPopulationsWidget
            hideControls
            invitations={invitations}
            selectedDateType={
              props._session.dashboardDisplaySettings.hideStartedForParticipation ? "dateDone" : "dateStarted"
            }
          />
        ) : (
          <Card exportName="participation-progress" className="flex1">
            <DashboardParticipationProgressWidget
              invitations={invitations}
              selectedDateType={
                props._session.dashboardDisplaySettings.hideStartedForParticipation ? "dateDone" : "dateStarted"
              }
            />
          </Card>
        )}
      </div>

      {!props.isPreview && (
        <Card exportName="participation-populations">
          <DashboardParticipationPopulationsWidget
            invitations={invitations}
            selectedDateType={
              props._session.dashboardDisplaySettings.hideStartedForParticipation ? "dateDone" : "dateStarted"
            }
          />
        </Card>
      )}
    </PageLoader>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
  survey: state.survey
})

export default connect(mapStateToProps)(DashboardParticipationWidget)
