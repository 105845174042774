/**
 * FORMAT-DATE.UTILS
 * Format a date to string
 */

import { t } from "@/translate/t"
import { toast } from "react-toastify"

export type Duration = {
  days: number
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
}

export function isSameDay(date1: Date, date2: Date) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}

export function isToday(date: Date) {
  return isSameDay(date, new Date())
}

export function msToTime(ms: number): Duration {
  const milliseconds: number = Math.floor((ms % 1000) / 100)
  const seconds: number = Math.floor((ms / 1000) % 60)
  const minutes: number = Math.floor((ms / (1000 * 60)) % 60)
  const hours: number = Math.floor((ms / (1000 * 60 * 60)) % 24)
  const days: number = Math.floor(ms / (1000 * 60 * 60 * 24))

  return { days, hours, minutes, seconds, milliseconds }
}

export function durationToString(duration: Duration): string {
  const days: string =
    duration.days > 0 ? t("utils_time_days", { value: duration.days, s: duration.days < 2 ? "" : "s" }) + " " : ""
  const hours: string =
    duration.hours > 0
      ? t("utils_time_hours", {
          value: duration.hours < 10 ? "0" + duration.hours : duration.hours,
          s: duration.hours < 2 ? "" : "s"
        }) + " "
      : ""
  const minutes: string =
    duration.minutes > 0
      ? t("utils_time_minutes", {
          value: duration.minutes < 10 ? "0" + duration.minutes : duration.minutes,
          s: duration.minutes < 2 ? "" : "s"
        }) + " "
      : ""
  const seconds: string =
    duration.seconds > 0
      ? t("utils_time_seconds", {
          value: duration.seconds < 10 ? "0" + duration.seconds : duration.seconds,
          s: duration.seconds < 2 ? "" : "s"
        }) + " "
      : ""
  const milliseconds: string =
    duration.milliseconds > 0
      ? t("utils_time_milliseconds", { value: duration.milliseconds, s: duration.milliseconds < 2 ? "" : "s" }) + " "
      : ""

  return days + hours + minutes + seconds + milliseconds
}

export function formatDate(date: Date | number | string, withTime: boolean, forInput: boolean): string {
  date = new Date(date)

  if (forInput && date.toString() !== "Invalid Date") {
    let response = date.toISOString().split("T")[0]

    if (withTime) {
      response +=
        "T" +
        date
          .toLocaleDateString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit"
          })
          .split(" ")[1]
    }

    return response
  } else {
    if (withTime) {
      return date.toLocaleDateString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit"
      })
    } else {
      return date.toLocaleDateString()
    }
  }
}

//Change date but keep same hour
export function updateDate(previousDate: Date, newDate: Date, isPastAllowed: boolean, hours: number | null) {
  newDate = new Date(newDate)
  previousDate = new Date(previousDate)

  //Keep hour and minutes same as the previous date
  newDate.setHours(hours ? hours : previousDate.getHours())
  newDate.setMinutes(previousDate.getMinutes())

  if (!isPastAllowed) {
    if (isToday(newDate) && newDate < new Date()) {
      toast(t("project_hour_error_before_now"), { type: "error" })
      newDate.setHours(new Date().getHours() + 2)
      newDate.setMinutes(0)
    } else if (newDate < new Date()) {
      toast(t("project_date_error_before_now"), { type: "error" })
      newDate = new Date(previousDate)
    }
  }

  return newDate
}
