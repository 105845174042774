/**
 * REPORT-SCREENSHOT-QUESTIONS.WIDGET.TSX
 * Preview of the slide
 */
import { connect } from "react-redux"
import { WithTranslation, withTranslation } from "react-i18next"
import { Session } from "@/redux/_session.types"
import { Topic, TopicState } from "@/redux/topic.types"
import { pageInitDashboardFilters } from "@/redux/page.actions"
import { useEffect } from "react"
import { store } from "@/index"
import { loadQuestionsAndTopics } from "@/redux/_archive.actions"
import Space from "@/components/space"
import TopicRepartition from "@/components/topic-repartition"
import DashboardQuestionsNoteWidget from "./dashboard-questions-note.widget"
import { sortBy } from "lodash"
import { topicFilterReportQuestions } from "@/redux/topic.actions"
import { Page } from "@/redux/page.types"
import DashboardTopicHeaderWidget from "./dashboard-topic-header.widget"
import { Axis } from "@/redux/axis.types"
import ListItem from "@/components/list-item"

interface StateProps {
  _session: Session
  topic: TopicState
}

interface OwnProps {
  currentPage: Page
  currentTopics: Topic[]
  displayFullScale?: boolean
  topicsOnly?: boolean
}

type Props = StateProps & OwnProps & WithTranslation

function ReportScreenshotQuestionsWidget(props: Props) {
  const { t } = props

  //Load questions on every change on the dashboard filters
  useEffect(() => {
    const dashboardFilters = pageInitDashboardFilters(props.currentPage.options.populations)
    store.dispatch(loadQuestionsAndTopics(dashboardFilters))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentPage.id])

  return (
    <div
      style={{
        transform: props.topicsOnly
          ? "scale(" + (props.currentTopics.length >= 10 ? "0.38" : "0.45") + ")"
          : "scale(0.38)",
        transformOrigin: "top left",
        width: props.currentPage.twoColumns ? 800 : props.topicsOnly ? 1300 : 1675
      }}>
      {sortBy(props.currentTopics, "note")
        .reverse()
        .map((topic, i) => (
          <div key={topic.aid}>
            {props.topicsOnly ? (
              <ListItem>
                <div className="flex1">
                  {i === 0 && topic.Axis && (
                    <div style={{ color: topic.Axis.color, fontSize: 32, marginBottom: 32 }}>
                      <b>{topic.Axis.label}</b>
                    </div>
                  )}

                  <DashboardTopicHeaderWidget
                    currentTopic={topic}
                    currentAxis={topic.Axis ? topic.Axis : new Axis()}
                    isPreview
                  />
                </div>
              </ListItem>
            ) : (
              topicFilterReportQuestions(topic.Questions).map((question, i) => (
                <div
                  key={question.aid}
                  className={"medgrey-b" + (props.currentPage.twoColumns ? "" : " flex")}
                  style={{
                    borderTop: "1px solid",
                    minHeight: props.currentPage.twoColumns ? 136 : 76,
                    padding: props.currentPage.twoColumns ? "20px 0" : ""
                  }}>
                  <div style={{ fontSize: 18, marginRight: 20, marginTop: 14 }}>
                    {i === 0 && (
                      <div className="flex" style={{ color: topic.Axis?.color }}>
                        {topic.label}
                        <div style={{ margin: "4px 8px" }}>
                          <DashboardQuestionsNoteWidget currentTopic={topic} />
                        </div>
                      </div>
                    )}
                    <div>{question.label}</div>
                  </div>

                  <Space />

                  {question.type === "scale" ? (
                    <TopicRepartition
                      isPreview
                      id={question.id}
                      repartition={props.displayFullScale ? question.repartition : question.repartitionTwoScales}
                      noAnswers={question.noAnswers}
                      reverse={question.reverse}
                    />
                  ) : (
                    <div
                      style={{
                        minWidth: 700,
                        margin: "8px 0px"
                      }}>
                      {sortBy(question.Propositions, "count")
                        .reverse()
                        .map((proposition) => (
                          <div key={proposition.aid} className="flex" style={{ margin: "8px 0px" }}>
                            {proposition.id === "others" ? t("utils_others") : proposition.label}

                            <Space />

                            <span className="grey-t">
                              {proposition.count} {proposition.count > 1 ? t("propositions") : t("proposition")}&nbsp;
                            </span>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        ))}
    </div>
  )
}
const mapStateToProps = (state) => ({
  _session: state._session,
  topic: state.topic
})

export default connect(mapStateToProps)(withTranslation()(ReportScreenshotQuestionsWidget))
