/**
 * PROJECT.TYPE
 * Configure a survey before launch
 */

import { faPaperPlane, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import env from "@/env"
import { flatten, groupBy, sortBy } from "lodash"
import i18n from "@/translate/i18n"
import { formatDate } from "@/utils/format-date.utils"
import { Email } from "./email.types"
import { Population } from "./population.types"
import { Reminder, REMINDER_TAGS } from "./reminder.types"
import { Sending } from "./sending.types"
import { Survey } from "./survey.types"
import { Template } from "./template.types"
import { StatusType } from "./_status.types"
import { v4 as uuid } from "uuid"
import { t } from "@/translate/t"
import { toast } from "react-toastify"

//List of step for a project
export type ProjectStep =
  | "name"
  | "test"
  | "template"
  | "modes"
  | "import"
  | "filters"
  | "recipients"
  | "segment"
  | "languages"
  | "message"
  | "qrcode"
  | "emails"
  | "calendar"

//Status of the step
export type ProjectStepStatus = "initial" | "locked" | "visited" | "confirmed" | "error" | "warning"

//List of status for the project
export type ProjectStatus = "draft" | "test" | "launch" | "closed"

export class StepAlert {
  errors: string[] = []
  warnings: string[] = []

  constructor(item: Partial<StepAlert> = {}) {
    Object.assign(this, item)
  }
}

export class ProjectAlerts {
  template: StepAlert = new StepAlert()
  modes: StepAlert = new StepAlert()
  import: StepAlert = new StepAlert()
  recipients: StepAlert = new StepAlert()
  segment: StepAlert = new StepAlert()
  filters: StepAlert = new StepAlert()
  languages: StepAlert = new StepAlert()
  message: StepAlert = new StepAlert()
  qrcode: StepAlert = new StepAlert()
  emails: StepAlert = new StepAlert()
  calendar: StepAlert = new StepAlert()

  constructor(item: Partial<ProjectAlerts> = {}) {
    Object.assign(this, item)
  }
}

//Specific rule for segmentation by axis
//> for an axis you can define populations to invite
export class ProjectAxisRule {
  axisId: string = ""
  populations: Population[] = []

  constructor(item: Partial<ProjectAxisRule> = {}) {
    Object.assign(this, item)
  }
}

//Event that can be contain in a calendar cell
export interface ProjectCalendarEvent {
  id: string
  icon?: IconDefinition
  backgroundColor: string
  color: string
  start: Date
  end: Date
  items: ProjectCalendarEventItem[]
}

//List of events that can be contains in a calendar date
export interface ProjectCalendarEventItem {
  id: string
  color: string
  name: string
  date: Date
  dateString: string
  dateLabel: string
  reminder?: Reminder
  sending?: Sending
  projectId: string
}

//Modes of invitations (email, sms and qrcode can be activate)
export class ProjectDiffusionMode {
  email: boolean = true
  sms: boolean = false
  qrcode: boolean = false

  constructor(item: Partial<ProjectDiffusionMode> = {}) {
    Object.assign(this, item)
  }
}

//Event for project (from reminder and sendings)
export interface ProjectEvent {
  color: string
  date: Date
  dateLabel: string
  name: string
}

//Invitation message content
export class ProjectMessageOptions {
  backgroundColor: string | null = null
  buttonColor: string | null = null
  confirmationContent: any = {}
  confirmationTitle: any = {}
  confirmationSender: any = {}
  content: any = {}
  displayImage: boolean = true
  infoContent: any = {}
  isAlternativeLayout: boolean = false
  isConfirmationSend: boolean = false //Send confirmation message when survey is done
  isInfoCustom: boolean = false
  isWithTitleSecondary: boolean = false
  textColor: string | null = null
  title: any = {}
  titleSecondary: any = {}
  constructor(item: Partial<ProjectMessageOptions> = {}) {
    Object.assign(this, item)
  }
}

//Options for the QRCode to generate
export class ProjectQrcodeOptions {
  attributeToDisplay: null | string = null
  attributeToDisplay2: null | string = null
  content: any = {}
  displayAttribute: boolean = false
  displayAttribute2: boolean = false
  hideAddress: boolean = false
  hideCode: boolean = false
  senderName: string = ""
  senderAddress: string = ""

  constructor(item: Partial<ProjectQrcodeOptions> = {}) {
    Object.assign(this, item)
  }

  get senderAddresses(): string[] {
    return this.senderAddress.split(",")
  }
}

/**
 * CLASS PROJECT
 */
export class Project {
  alerts: ProjectAlerts = new ProjectAlerts() //Warning and errors for each steps
  createdAt: Date = new Date() //Date of creation (system attribute in the DB)
  customImage: boolean = false //false : use the template image, true : use project image (if uploaded)
  diffusionMode: ProjectDiffusionMode = new ProjectDiffusionMode() //Mode of diffusion (email, sms, qrcode)
  emailSkin: number | null = null //skin id that will be used for sendings
  emailsOrder: string[] = [] //Order of the emails
  excludedUsers: string[] = [] //List of user ids that won't be invited for the survey
  id: string = "" //Id of the survey rule (uuid)
  image: boolean = false //project image
  inactiveWarnings: ProjectStep[] = [] //List of steps that have disabled warning
  isClosed: boolean = false //Is project at the end
  isSendToEveryone: boolean = true //Is true invitations will be generated for everyone in the account
  isSegmentationActive: boolean = false //Activate advanced segmentation
  languages: string[] = [] //List of languages selected
  messageOptions: ProjectMessageOptions = new ProjectMessageOptions() //Content of the invitation message
  name: string = "" //Name of the survey (by default name of the questionnaire)
  populations: Population[] = [] //List of populations invited (is not isSendToEveryone)
  qrcodeOptions: ProjectQrcodeOptions = new ProjectQrcodeOptions() //Options for the qr code generation
  segmentationByAxesRules: ProjectAxisRule[] = [] //Segmentation by axes for the survey
  showWarnings: boolean = true //Show warning alerts for each step in project edit
  surveyDateEnd: Date | null = null //Define date for surveyEnd
  stepsConfirmed: ProjectStep[] = [] //List of steps confirmed
  stepsVisited: ProjectStep[] = [] //List of steps already opened
  Surveys: Survey[] = [] //List of survey for the project
  Template: Template = new Template() //Display template general informations
  TemplateId: null | string = null //Id of the selected template
  updatedAt: Date = new Date() //Last update
  usersForTest: string[] = [] //List of user for testing

  constructor(item: Partial<Project> = {}) {
    if (!item) item = new Project()
    item.alerts = item.alerts ? new ProjectAlerts(item.alerts) : new ProjectAlerts()
    item.diffusionMode = new ProjectDiffusionMode(item.diffusionMode)
    item.messageOptions = item.messageOptions
      ? new ProjectMessageOptions(item.messageOptions)
      : new ProjectMessageOptions()
    item.populations = item.populations ? item.populations.map((x) => new Population(x)) : []
    item.qrcodeOptions = item.qrcodeOptions ? new ProjectQrcodeOptions(item.qrcodeOptions) : new ProjectQrcodeOptions()
    item.segmentationByAxesRules = item.segmentationByAxesRules
      ? item.segmentationByAxesRules.map((x) => new ProjectAxisRule(x))
      : []
    item.Surveys = item.Surveys ? item.Surveys.map((x) => new Survey(x)) : []
    item.Template = new Template(item.Template)
    Object.assign(this, item)
  }

  //Date of creation (label)
  get createdAtLabel(): string {
    return formatDate(this.createdAt, true, false)
  }

  //Display modes
  get diffusionModeLabel(): string {
    const modes: string[] = []
    if (this.diffusionMode.email) modes.push(t("project_modes_email_short"))
    if (this.diffusionMode.sms) modes.push(t("project_modes_sms_short"))
    if (this.diffusionMode.qrcode) modes.push(t("project_modes_qrcode_short"))
    return modes.join(", ")
  }

  get inactiveDiffusionModes(): string[] {
    return Object.keys(this.diffusionMode).filter((mode: string) => this.diffusionMode[mode] === false)
  }

  //Get image url
  get imageUrl(): string {
    return this.image
      ? env.REACT_APP_URL_SPACE.concat("/Projects/", this.id, ".png?" + Date.now())
      : require("@/assets/add.png")
  }

  get picture(): string {
    return !this.customImage && this.Template.image
      ? env.REACT_APP_URL_SPACE.concat("/Templates/", this.Template.image, ".png?" + Date.now())
      : this.imageUrl
  }

  //Is diffusion mode defined
  get isDiffusionModeDefined(): boolean {
    return this.diffusionMode.email || this.diffusionMode.sms || this.diffusionMode.qrcode
  }

  //Is project launch
  get isLaunch(): boolean {
    return this.status === "test" || this.status === "launch"
  }

  //Get last survey for the project
  get lastSurvey(): Survey | null {
    return this.Surveys.length ? sortBy(this.Surveys, ["dateStart"]).reverse()[0] : null
  }

  //Get paying modules
  get paywallSteps(): ProjectStep[] {
    return ["segment", "languages", "qrcode"]
  }

  //Return status for the project
  get status(): ProjectStatus {
    if (this.isClosed) {
      return "closed"
    } else if (this.lastSurvey) {
      if (this.lastSurvey.dateEnd) {
        return "draft"
      } else if (this.lastSurvey.isTest) {
        return "test"
      } else {
        return "launch"
      }
    } else {
      return "draft"
    }
  }

  //Couleur du statut de l'enquête
  get statusColor(): string {
    switch (this.status) {
      case "test":
        return "#8BBDD9"
      case "launch":
        return "#094C72"
      default:
        return ""
    }
  }

  //Return text for status
  get statusLabel(): string {
    return t("project_status_" + this.status)
  }

  //Get link to test the template
  get testTemplateLink(): string {
    return (
      env.REACT_APP_URL_QUICKVOTE +
      "/test?projectId=" +
      this.id +
      "&templateId=" +
      (this.TemplateId ? this.TemplateId : "") +
      "&isPreview=true"
    )
  }

  //Date of creation (label)
  get updatedAtLabel(): string {
    return formatDate(this.createdAt, true, false)
  }

  get errorsFlatten(): string[] {
    return flatten(Object.values(this.alerts).map((step: StepAlert) => step.errors))
  }

  get warningsFlatten(): string[] {
    return flatten(Object.values(this.alerts).map((step: StepAlert) => step.warnings))
  }

  //Get project axis rule for specific axis
  getAxisRule(axisId: string): ProjectAxisRule | undefined {
    return this.segmentationByAxesRules.find((x) => x.axisId === axisId)
  }

  //Return populations for axis rule
  getAxisRulePopulations(axisId): Population[] {
    const axisRule = this.getAxisRule(axisId)
    return axisRule ? axisRule.populations : []
  }

  //Get list of events
  getEvents(sendings: Sending[], reminders: Reminder[], emails: Email[]): ProjectCalendarEvent[] {
    const events: ProjectCalendarEvent[] = []
    const items: ProjectCalendarEventItem[] = []

    sendings.forEach((sending) => {
      const email = new Email(emails.find((x) => x.id === sending.EmailId))
      items.push({
        id: sending.id,
        color: "",
        date: sending.date,
        dateString: sending.date.toDateString(),
        dateLabel: formatDate(sending.date, true, false),
        name: email.name,
        sending,
        projectId: sending.ProjectId
      })
    })

    reminders.forEach((reminder) => {
      items.push({
        id: reminder.id,
        color: "",
        date: reminder.date,
        dateString: reminder.date.toDateString(),
        dateLabel: formatDate(reminder.date, true, false),
        name: reminder.title,
        reminder,
        projectId: reminder.ProjectId
      })
    })

    //Date end of the survey
    if (this.surveyDateEnd) {
      const surveyDateEnd = new Date(this.surveyDateEnd)
      items.push({
        id: "surveyDateEnd",
        color: "#eb5a46",
        date: surveyDateEnd,
        dateString: new Date(surveyDateEnd).toDateString(),
        dateLabel: formatDate(new Date(surveyDateEnd), false, false),
        name: t("reminder_tag_end"),
        projectId: this.id
      })
    }

    const groupByItems = groupBy(items, "dateString")
    Object.keys(groupByItems).forEach((group) => {
      const items = groupByItems[group]

      let color = "white"
      let backgroundColor = "#0082A0"

      let icon
      for (let i = 0; i < items.length; i++) {
        const item = items[i]

        if (item.sending) {
          icon = faPaperPlane
          backgroundColor = item.sending.getIsFirst(sendings) ? "#eb5a46" : "#ff9500"
          item.color = backgroundColor
          break
        }

        if (item.reminder) {
          const calendarEvent = REMINDER_TAGS.find((x) => x === item.reminder?.tag)
          color = calendarEvent ? "#00c2e0" : "#0082a0"
          backgroundColor = "white"
          item.color = color
        }
      }

      events.push({
        id: uuid(),
        icon,
        backgroundColor,
        color,
        start: new Date(new Date(group).setHours(9)),
        end: new Date(new Date(group).setHours(9)),
        items: sortBy(items, "date")
      })
    })

    return events
  }

  //Format message options
  getInitialMessageOptions(accountName: string, projectName: string): ProjectMessageOptions {
    return {
      backgroundColor: null,
      buttonColor: null,
      isAlternativeLayout: false,
      isInfoCustom: false,
      isWithTitleSecondary: false,
      isConfirmationSend: false,
      infoContent: {},
      confirmationContent: {},
      confirmationTitle: {},
      confirmationSender: {},
      content: {
        [i18n.language]: t("survey_letter_body_message", {
          account: accountName
        })
      },
      textColor: null,
      titleSecondary: {},
      title: {
        [i18n.language]: projectName
      },
      displayImage: true
    }
  }

  //Get next event from reminder and sendings
  getNextEvent(sendings: Sending[], reminders: Reminder[], withLabel: boolean, emails: Email[]): ProjectEvent {
    let events = sortBy(
      sendings
        .map((x, i) => {
          return {
            color: i === 0 ? "#eb5a46" : "#ff9500",
            date: x.date,
            dateLabel: "-",
            name: x.EmailId
          }
        })

        .concat(
          reminders.map((x) => {
            return {
              color: x.tag === "custom" ? "#0082A0" : "#00c2e0",
              date: x.date,
              dateLabel: "-",
              name: x.title
            }
          })
        )

        .filter((x) => x.date >= new Date()),
      "date"
    )

    events = events.filter(
      (x) =>
        (x.dateLabel = x.date.toLocaleDateString(i18n.language, {
          month: "long",
          day: "numeric"
        }))
    )

    //Format email mail if next event is an email
    if (events[0]) {
      const email = emails.find((x) => x.id === events[0].name)
      if (email) events[0].name = email.name

      if (withLabel) {
        events = events.filter((x) => (x.name = t("calendar_next") + " : " + x.name))
      }

      return events[0]
    } else {
      return {
        color: "grey",
        date: new Date(),
        dateLabel: "-",
        name: t("calendar_empty")
      }
    }
  }

  getStepStatus(
    errorsCount: number,
    warningCount: number,
    showWarnings: boolean,
    isConfirmed: boolean,
    isVisited: boolean
  ): ProjectStepStatus {
    if (errorsCount > 0 && isConfirmed) {
      return "error"
    } else if (warningCount > 0 && showWarnings && isConfirmed) {
      return "warning"
    } else if (isConfirmed) {
      return "confirmed"
    } else if (isVisited) {
      return "visited"
    } else {
      return "initial"
    }
  }

  //Check if surveyDateEnd is before the last date of the dates array
  //If it is the case, return isLast = true
  isDateEndValid(newDateEnd: Date | null, dates: Date[]) {
    let isValid: boolean = true

    if (newDateEnd && dates.length > 0) {
      // Trier le tableau du plus récent au plus ancien
      const sortedDates = dates.sort((a, b) => b.getTime() - a.getTime())
      const lastDate = sortedDates[0] // Récupérer la dernière date (la plus ancienne)
      if (new Date(newDateEnd) < new Date(lastDate)) {
        isValid = false
        toast(t("reminder_tag_end_error"), { type: "error" })
      }
    }

    return isValid
  }

  isStepWarningActive(step: ProjectStep): boolean {
    return this.inactiveWarnings.indexOf(step) === -1
  }

  isLocked(step: ProjectStep): boolean {
    return this.status !== "draft" && ["test", "template", "import", "recipients"].indexOf(step) > -1
  }
}

export interface ProjectState {
  active: Project
  list: Project[]
  status: StatusType
}

export const PROJECT_STATUS_LIST: ProjectStatus[] = ["draft", "test", "launch", "closed"]

export const PROJECT_ACTIVATE: string = "PROJECT_ACTIVATE"
export const PROJECT_EDIT: string = "PROJECT_EDIT"
export const PROJECT_EDIT_ALERTS: string = "PROJECT_EDIT_ALERTS"
export const PROJECT_EDIT_AXIS_RULE: string = "PROJECT_EDIT_AXIS_RULE"
export const PROJECT_EDIT_MESSAGE_OPTIONS: string = "PROJECT_EDIT_MESSAGE_OPTIONS"
export const PROJECT_EDIT_QRCODE_OPTIONS: string = "PROJECT_EDIT_QRCODE_OPTIONS"
export const PROJECT_GET: string = "PROJECT_GET"
export const PROJECT_INIT: string = "PROJECT_INIT"
export const PROJECT_REMOVE: string = "PROJECT_REMOVE"
export const PROJECT_STATUS: string = "PROJECT_STATUS"
