/**
 * DASHBOARD-TOPIC-HEADER.WIDGET
 * Preview for the topic (note and repartition)
 */

import { withTranslation, WithTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import { connect } from "react-redux"
import { Topic } from "@/redux/topic.types"
import { Axis } from "@/redux/axis.types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faCaretRight, faClone, faCommentDots, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import ListButton from "@/components/list-button"
import { Session } from "@/redux/_session.types"
import getRepartitionColor from "@/utils/get-repartition-color.utils"
import DashboardQuestionsNoteWidget from "./dashboard-questions-note.widget"
import DashboardPropositionWidget from "./dashboard-proposition.widget"
import { getRepartitionRatio } from "@/utils/satisfaction.utils"
import Space from "@/components/space"

interface StateProps extends WithTranslation {
  _session: Session
}

interface OwnProps {
  currentTopic: Topic
  currentAxis: Axis
  isPreview?: boolean
  hideProgressBar?: boolean
  onOpen?: Function
}

type Props = StateProps & OwnProps

function DashboardTopicHeaderWidget(props: Props) {
  const { t } = props

  //Style for repartition
  function getRepartitionStyle(count, i, repartition) {
    return {
      backgroundColor: getRepartitionColor(i, 1, false, props._session.dashboardDisplaySettings, false),
      width: getRepartitionRatio(count, repartition) + "%"
    }
  }

  //Open topic
  function open() {
    if (props.onOpen) {
      props.onOpen()
    }
  }

  return (
    <div>
      <div className={"flex " + (props.isPreview ? "details-topic-header-preview" : "")}>
        {props.onOpen && (
          <div className="flex flex-dcol">
            <Space />
            <ListButton onClick={() => open()} icon={props.currentTopic.open ? faCaretDown : faCaretRight} />
            <Space />
          </div>
        )}

        <div className="flex flex-dcol">
          <Space />
          <DashboardQuestionsNoteWidget currentTopic={props.currentTopic} />
          <Space />
        </div>

        {!props.currentTopic.withScore && (
          <div style={{ color: props.currentAxis.color }} className="flex flex-dcol details-topic-comment">
            <Space />
            <FontAwesomeIcon icon={faCommentDots} />
            <Space />
          </div>
        )}

        <div className="flex details-topic-text">
          <div
            style={{
              fontWeight: props.currentTopic.open ? "bold" : undefined,
              fontSize: props.isPreview ? 24 : undefined
            }}>
            {props.currentTopic.label + "   "}
          </div>

          <div className="flex flex-dcol">
            <Space />

            {props.currentTopic.Questions.length > 1 && !props.isPreview ? (
              <div className="grey-t width-40" data-tip={props.currentTopic.Questions.length + " " + t("questions")}>
                <FontAwesomeIcon icon={faClone} /> {props.currentTopic.Questions.length}
                <ReactTooltip />
              </div>
            ) : (
              <div className="width-40" />
            )}

            <Space />
          </div>
        </div>

        <Space />

        {!props.hideProgressBar && (
          <div className="flex flex-dcol" style={{ width: 400 }}>
            <Space />

            <div className="flex">
              <div
                className="details-topic-repartition flex"
                style={{
                  width: !props._session.dashboardDisplaySettings.hideNoAnswers
                    ? props.currentTopic.answersRatio * 3.75
                    : 400
                }}>
                {[props.currentTopic.repartition[0], props.currentTopic.repartition[1]].map((count, i) => (
                  <div
                    key={i}
                    className="details-topic-repartition-item"
                    style={getRepartitionStyle(count, i, props.currentTopic.repartition)}>
                    {getRepartitionRatio(count, [...props.currentTopic.repartition, props.currentTopic.noAnswers]) >
                      10 && (
                      <span>
                        {getRepartitionRatio(count, [
                          ...props.currentTopic.repartition,
                          props.currentTopic.noAnswers
                        ]).toFixed(1)}{" "}
                        %
                      </span>
                    )}
                  </div>
                ))}
              </div>

              {!props._session.dashboardDisplaySettings.hideNoAnswers && (
                <div
                  className="details-topic-repartition-item"
                  style={{
                    marginLeft: 6,
                    backgroundColor: "#d5d4d4",
                    width: props.currentTopic.noAnswersRatio * 3.75,
                    height: 18,
                    borderRadius: 8
                  }}></div>
              )}

              {props.currentTopic.repartition.length > 0 && !props.isPreview && (
                <div style={{ margin: "auto", marginLeft: 6 }}>
                  <FontAwesomeIcon
                    data-for={"repartition-" + props.currentTopic.id}
                    data-tip={t("topic_repartition_help_2", {
                      topic: props.currentTopic.label,
                      note: props._session.accountOptions.topicRepartitionCeil / 10
                    })}
                    icon={faInfoCircle}
                    className="_hover medgrey-t"
                  />
                  <ReactTooltip id={"repartition-" + props.currentTopic.id} />
                </div>
              )}
            </div>

            <Space />
          </div>
        )}
      </div>

      {!props.currentTopic.open && props.currentTopic.propositionsOverview.length > 0 && (
        <div className="flex">
          <div className="flex1"></div>

          <div className="details-proposition-container">
            {props.currentTopic.propositionsOverview.map((proposition, i) => (
              <div key={i}>
                <DashboardPropositionWidget currentAxis={props.currentAxis} currentProposition={proposition} />
              </div>
            ))}

            {props.currentTopic.Propositions.length > props.currentTopic.propositionsOverview.length && (
              <div className="flex details-proposition grey-t">
                {t("utils_show_others", {
                  count: props.currentTopic.Propositions.length - props.currentTopic.propositionsOverview.length,
                  s:
                    props.currentTopic.Propositions.length - props.currentTopic.propositionsOverview.length > 1
                      ? "s"
                      : ""
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session
})

export default connect(mapStateToProps)(withTranslation()(DashboardTopicHeaderWidget))
