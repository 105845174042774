/**
 * PROJECT-EDIT-EDIT.MODAL
 * Set end date for a project
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import Modal from "@/components/modal"
import { ProjectState } from "@/redux/project.types"
import { useState } from "react"
import { store } from "@/index"
import { projectEdit } from "@/redux/project.actions"
import TextInput from "@/components/text-input"
import { formatDate, updateDate } from "@/utils/format-date.utils"
import { SendingState } from "@/redux/sending.types"

interface StateProps extends WithTranslation {
  project: ProjectState
  sending: SendingState
}

interface OwnProps {
  date: Date
  onClose: Function
}

type Props = StateProps & OwnProps

function ProjectEditEndModal(props: Props) {
  const { t } = props

  const [date, setDate] = useState<Date>(initDate())

  function initDate() {
    if (props.date) {
      return new Date(new Date(props.date).setHours(23))
    } else {
      return props.project.active.surveyDateEnd
        ? new Date(props.project.active.surveyDateEnd)
        : new Date(new Date().setDate(new Date().getDate() + 30))
    }
  }

  //Proceed on next
  async function next() {
    if (
      props.project.active.isDateEndValid(
        date,
        props.sending.list.map((x) => x.date)
      )
    ) {
      store.dispatch(projectEdit("surveyDateEnd", date))
      props.onClose()
    }
  }

  //Delete the date end
  async function deleteEnd() {
    store.dispatch(projectEdit("surveyDateEnd", null))
    props.onClose()
  }

  return (
    <Modal
      title={t("reminder_tag_end")}
      isCloseButtonVisible
      disableKeyEvents
      disableClickOutside
      status={props.project.status}
      onClose={() => props.onClose()}
      onDelete={() => deleteEnd()}
      onNext={next}>
      <p className="grey-t">{t("reminder_description_end")}</p>
      <TextInput
        type="date"
        isFullWidth
        onChange={(e) => setDate(updateDate(date, new Date(e.value), false, 23))}
        value={formatDate(date, false, true)}
      />
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  project: state.project,
  sending: state.sending
})

export default connect(mapStateToProps)(withTranslation()(ProjectEditEndModal))
