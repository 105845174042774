/**
 * REPORT-SCREENSHOT-REPARTITION.WIDGET.TSX
 * Preview of the slide for the repartition widget
 */
import Space from "@/components/space"
import DashboardRepartitionWidget from "./dashboard-repartition.widget"

interface OwnProps {
  isFullWidth?: boolean
}

function ReportScreenshotRepartitionWidget(props: OwnProps) {
  return (
    <div
      className="flex"
      style={{
        marginTop: 80,
        transform: props.isFullWidth ? "scale(0.90)" : "scale(0.75)",
        transformOrigin: "top left"
      }}>
      <Space />
      <DashboardRepartitionWidget isOverview />
      <Space />
    </div>
  )
}

export default ReportScreenshotRepartitionWidget
