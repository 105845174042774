/**
 * NAV-APP
 * Container for account application
 * First level of navigation (with visible menu)
 */

import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import NavItem from "@/components/nav-item"
import {
  faChartBar,
  faClipboardList,
  faFileAlt,
  faFilm,
  faHome,
  faLock,
  faPaperPlane,
  faTasks,
  faUserFriends
} from "@fortawesome/free-solid-svg-icons"
import ListItem from "@/components/list-item"
import NavAvatar from "@/components/nav-avatar"
import Space from "@/components/space"
import { Session } from "@/redux/_session.types"
import logout from "@/utils/logout.utils"
import { useEffect, useState } from "react"
import SetPasswordModal from "@/modals/set-password.modal"
import MenuAccountsWidget from "@/widgets/menu-accounts.widget"
import Link from "@/components/link"
import AccountLogoWidget from "@/widgets/account-logo.widget"
import backToSupervisor from "@/utils/back-to-supervisor.utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AdmInvitationsRemainingWidget from "@/widgets/adm-invitations-remaining.widget"
import { Subscription } from "@/redux/subscription.types"
import getBillings from "@/utils/get-billings.utils"
import { sum } from "lodash"
import HomeWelcomeModal from "@/modals/home-welcome.modal"
import { RotateInDownLeft, ZoomIn } from "@/utils/animations.utils"
import { NavigateFunction, useNavigate } from "react-router-dom"
import AccountExpiredModal from "@/modals/account-expired.modal"
import UnpaidBillsModal from "@/modals/unpaid-bills.modal"

interface StateProps extends WithTranslation {
  _session: Session
}

interface OwnProps {
  children: any //Content inside the page
}

type Props = StateProps & OwnProps

const MODAL_SET_PASSWORD: string = "MODAL_SET_PASSWORD" //Invitation to create password
const MODAL_UNPAID_BILLS: string = "MODAL_UNPAID_BILLS"
const MODAL_WELCOME: string = "MODAL_WELCOME"

function NavApp(props: Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const searchParams: URLSearchParams = new URLSearchParams(window.location.search)
  const isWelcomeMode: boolean =
    searchParams.get("modal") === "welcome" && props._session.interfaceType !== "SUPERADMIN"

  //Get navItems
  const [navItems, setNavItems] = useState<any[]>([])

  //Get non paid bills
  const [unpaidBills] = useState<Subscription[]>(getBillings().filter((x: Subscription) => !x.paid && x.price > 0))

  //Current dialog
  const [currentModal, setCurrentModal] = useState<string | null>(isWelcomeMode ? MODAL_WELCOME : null)

  const [alternativeOwlAnimation, setAlternativeOwlAnimation] = useState<boolean>(false)

  const [block, setBlock] = useState<boolean>(
    !props._session.supervisorId && props._session.accountLastSubscriptionEnd
      ? props._session.accountLastSubscriptionEnd < Date.now()
      : false
  )

  //Detect if user is log or not
  //If not log out
  useEffect(() => {
    if (props._session.authLevel < 1) {
      logout(true)
    }
  }, [props._session.authLevel])

  useEffect(() => {
    setBlock(
      !props._session.supervisorId && props._session.accountLastSubscriptionEnd
        ? props._session.accountLastSubscriptionEnd < Date.now()
        : false
    )
  }, [props._session.accountLastSubscriptionEnd, props._session.supervisorId])

  //Go to specific page
  function toPage(pathname) {
    navigate(pathname)
  }

  //Update navItems
  useEffect(() => {
    //Get items for the menu top
    function getNavItems() {
      //Init nav items
      const navItems: any[] = []

      //Init report item
      const reportItem = {
        text: "reports",
        pathname: "/report/list",
        icon: faFileAlt
      }

      //Init task item
      const taskItem = {
        text: "tasks",
        pathname: "/tasks/home",
        icon: faTasks
      }

      //Admins accès
      if (props._session.userRole === "ADMIN") {
        //Add templates and results
        navItems.push({
          text: "templates",
          pathname: "/survey/",
          icon: faClipboardList,
          subItems: [
            { text: "templates_account", pathname: "/survey/account" },
            { text: "templates_public", pathname: "/survey/templates" }
          ]
        })

        //Add project if admin
        if (props._session.interfaceType === "ADMIN") {
          navItems.push({
            text: "projects",
            pathname: "/project/list",
            icon: faPaperPlane
          })
        }

        //Add results if admin or account is not type consulting (not useful)
        if (!props._session.isSuperAccount || props._session.interfaceType === "ADMIN") {
          navItems.push({
            text: "dashboard_results",
            pathname: "/dashboard/home",
            icon: faChartBar
          })
        }

        //Add reports
        navItems.push(reportItem)

        //Add tasks
        if (props._session.modules.task) {
          navItems.push(taskItem)
        }

        //Adm subItems differents according interface type
        let admSubItems: any[] = []
        if (props._session.interfaceType === "SUPERADMIN") {
          admSubItems = [
            { text: "superadmin_users", pathname: "/superadmin/users" },
            { text: "superadmin_templates", pathname: "/superadmin/templates" },
            { text: "utils_params", pathname: "/superadmin/settings" }
          ]

          //Mes comptes only for connectedAsSUpervisor
          if (props._session.connectedAsSupervisor) {
            admSubItems.push({ text: "superadmin_accounts_menu", pathname: "/superadmin/accounts" })
          }
        } else {
          admSubItems = admSubItems.concat([
            { text: "roles", pathname: "/adm/roles" },
            { text: "users", pathname: "/adm/users" },
            { text: "filters_custom", pathname: "/adm/filters" },
            { text: "account_settings", pathname: "/adm/settings" }
          ])
        }

        //Add adm (name depends of the account)
        navItems.push({
          text: props._session.accountName,
          pathname: props._session.interfaceType === "SUPERADMIN" ? "/superadmin/" : "/adm/",
          icon: faUserFriends,
          subItems: admSubItems
        })
      } else if (props._session.userRole === "OBSERVER") {
        if (props._session.modules.task) {
          navItems.push(taskItem)
        }

        navItems.push(reportItem)
      }

      //Add learning icon
      if (props._session.modules.learning) {
        navItems.push({
          text: "learning",
          pathname: "/learning/home",
          icon: faFilm
        })
      }

      //Add home for observer
      if (props._session.userRole === "OBSERVER") {
        navItems.unshift({
          text: "utils_home",
          pathname: "/home/account",
          icon: faHome
        })
      }

      return navItems
    }

    setNavItems(getNavItems())
  }, [
    props._session.connectedAsSuperadmin,
    props._session.isSuperAccount,
    props._session.modules.learning,
    props._session.modules.report,
    props._session.modules.task,
    props._session.accountName,
    props._session.interfaceType,
    props._session.connectedAsSupervisor,
    props._session.userRole
  ])

  //Get menu left
  //If there are many items show the menu
  function getNavSubItems() {
    const currentNavItem = navItems.find((x) => window.location.pathname.indexOf(x.pathname) > -1)
    if (currentNavItem) {
      return currentNavItem.subItems ? currentNavItem.subItems : []
    } else {
      return []
    }
  }

  function renderOwlFigure() {
    return (
      <div
        className="abs _hover"
        style={{
          right: 30,
          bottom: 20,
          height: 60,
          width: 60,
          borderRadius: 30,
          backgroundColor: props._session.accountColors.active,
          zIndex: 10
        }}
        onClick={() => setCurrentModal(MODAL_WELCOME)}>
        <img
          src={require("@/assets/assistant/owl_female_face.png")}
          alt={"owl assistant"}
          width={60}
          style={{ objectFit: "contain" }}
        />
      </div>
    )
  }

  return (
    <div>
      <MenuAccountsWidget />

      {currentModal === MODAL_SET_PASSWORD && (
        <SetPasswordModal onClose={() => setCurrentModal(null)} passwordMinLength={11} />
      )}

      {block && <AccountExpiredModal expirationDate={props._session.accountLastSubscriptionEnd} />}

      {currentModal === MODAL_UNPAID_BILLS && (
        <UnpaidBillsModal unpaidBills={unpaidBills} onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_WELCOME && (
        <HomeWelcomeModal
          onClose={() => {
            setAlternativeOwlAnimation(true)
            setCurrentModal(null)
          }}
        />
      )}

      {
        /** warning for password */
        !props._session.userPasswordProvided &&
          props._session.supervisorLevel === 0 &&
          props._session.connexionMode !== "GOOGLE" &&
          !props._session.modules.sso && (
            <div className="flex page-navigation-footer">
              <div style={{ margin: "auto" }}>
                {t("self_password_empty")}&nbsp;
                <span onClick={() => setCurrentModal(MODAL_SET_PASSWORD)}>{t("self_password_create")}</span>
              </div>
            </div>
          )
      }

      {/** header */}
      <div
        className="flex page-navigation-menutop grey-bor"
        style={{
          background:
            "linear-gradient(90deg, " +
            props._session.accountColors.brandPrimary +
            " 0%, " +
            props._session.accountColors.brandSecondary +
            " 100%)",
          paddingLeft: props._session.connectedAsSuperadmin ? "52px" : "0px",
          width: props._session.connectedAsSuperadmin ? "calc(100% - 52px)" : "100%"
        }}>
        <AccountLogoWidget />

        {
          /** loop on menu items */
          navItems.map((item) => (
            <NavItem
              icon={item.icon}
              tooltip={t(item.text)}
              key={item.pathname}
              pathname={item.subItems ? item.subItems[0].pathname : item.pathname}
              title={t(item.text.length > 50 ? item.text.slice(0, 45) + " [...]" : item.text)}>
              {
                /** if there are sublevel **/
                item.subItems
                  ? item.subItems.map((subitem) => (
                      <ListItem isEditable isSmall key={subitem.pathname} onClick={() => toPage(subitem.pathname)}>
                        <span
                          className={subitem.isInactive ? "grey-t" : ""}
                          style={{
                            color:
                              subitem.pathname === window.location.pathname
                                ? props._session.accountColors.active
                                : undefined
                          }}>
                          {subitem.isInactive && <FontAwesomeIcon icon={faLock} style={{ marginRight: "6px" }} />}
                          {t(subitem.text)}
                        </span>
                      </ListItem>
                    ))
                  : null
              }
            </NavItem>
          ))
        }

        <div className="width-20" />

        <Space />

        <AdmInvitationsRemainingWidget />

        {props._session.connectedAsSupervisor && (
          <div className="flex flex-dcol" style={{ marginRight: "18px" }}>
            <Space />
            <Link onClick={backToSupervisor} isWhite isWithoutMargin>
              {t("utils_back")}
            </Link>
            <Space />
          </div>
        )}

        <NavAvatar>
          {props._session.supervisorLevel > 0 ? (
            <div className="flex page-navigation-subitem grey-t">
              <div className="context-menu-item">{t("self_params")}</div>
            </div>
          ) : (
            <div className="flex page-navigation-subitem" onClick={() => toPage("/adm/self")}>
              <div className="context-menu-item">{t("self_params")}</div>
            </div>
          )}
          <div className="flex page-navigation-subitem" onClick={() => logout(false)}>
            <div className="context-menu-item">{t("utils_logout")}</div>
          </div>
        </NavAvatar>
      </div>

      <div className="page-navigation page-navigation-withheader">
        {props._session.accountLastSubscriptionEnd && props._session.accountLastSubscriptionEnd < Date.now() && (
          <div
            className="flex flex-dcol"
            style={{
              width: "100%",
              height: 40,
              backgroundColor: "#eb5a46", //"#df1324",
              color: "white",
              zIndex: 100
            }}>
            <Space />
            <div className="_hover" style={{ marginLeft: props._session.supervisorLevel > 0 ? 62 : 10 }}>
              <b>
                {t("account_subscription_expired_on", {
                  date: new Date(props._session.accountLastSubscriptionEnd).toLocaleDateString()
                })}
              </b>
            </div>
            <Space />
          </div>
        )}

        {unpaidBills.length > 0 &&
          sum(unpaidBills.map((x: Subscription) => x.price)) > 0 &&
          currentModal !== MODAL_UNPAID_BILLS && (
            <div
              className="flex flex-dcol orange-t"
              style={{
                width: "100%",
                height: 40,
                backgroundColor: "#d9d9d9", //"#df1324",
                zIndex: 100
              }}
              onClick={() => setCurrentModal(MODAL_UNPAID_BILLS)}>
              <Space />
              <div className="_hover" style={{ marginLeft: props._session.supervisorLevel > 0 ? 62 : 10 }}>
                <b>{t("unpaid_bills_information")}</b>
              </div>
              <Space />
            </div>
          )}

        <div className="flex">
          {props._session.connectedAsSuperadmin && <div style={{ width: "52px" }} />}

          {getNavSubItems().length > 0 && (
            <div className="page-navigation-menuleft">
              {getNavSubItems().map((page, index) => (
                <div key={index} onClick={() => toPage(page.pathname)} className="page-navigation-menuleft-item">
                  <span
                    className={
                      (window.location.pathname.indexOf(page.pathname) > -1
                        ? "page-navigation-menuleft-item-active"
                        : "") + (page.isInactive ? " grey-t" : "")
                    }>
                    {page.isInactive && <FontAwesomeIcon icon={faLock} style={{ marginRight: "6px" }} />}

                    {t(page.text)}
                  </span>
                </div>
              ))}
            </div>
          )}

          <div
            id="page-navigation-scrollable"
            className="flex1 page-navigation-children page-navigation-scrollable flex flex-dcol"
            style={{ height: "calc(100vh - 88px)" }}>
            <div className="flex">
              {getNavSubItems().length === 0 && <Space />}

              <div
                className={getNavSubItems().length > 0 ? "flex1" : ""}
                style={{ width: getNavSubItems().length > 0 ? undefined : "1000px" }}>
                {props.children}
              </div>

              {getNavSubItems().length === 0 && <Space />}
            </div>

            <div className="height-60" />
          </div>
        </div>
      </div>

      {props._session.userRole === "ADMIN" &&
        props._session.interfaceType === "ADMIN" &&
        currentModal !== MODAL_WELCOME &&
        (alternativeOwlAnimation ? (
          <RotateInDownLeft>
            <ZoomIn>{renderOwlFigure()}</ZoomIn>
          </RotateInDownLeft>
        ) : (
          <div>{renderOwlFigure()}</div>
        ))}
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session
})

export default connect(mapStateToProps)(withTranslation()(NavApp))
