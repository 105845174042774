/**
 * REPORT-SCREENSHOT-PARTICIPATION.WIDGET.TSX
 * Preview of the slide for the participation widget
 */
import Space from "@/components/space"
import DashboardParticipationOverviewWidget from "./dashboard-participation-overview.widget"
import DashboardParticipationWidget from "./dashboard-participation.widget"

interface OwnProps {
  isFullWidth?: boolean
}

function ReportScreenshotParticipationWidget(props: OwnProps) {
  return (
    <div
      className="flex"
      style={{
        transform: "scale(0.65)",
        transformOrigin: "top left"
      }}>
      <Space />
      {props.isFullWidth ? (
        <DashboardParticipationWidget isPreview />
      ) : (
        <DashboardParticipationOverviewWidget width={400} />
      )}
      <Space />
    </div>
  )
}

export default ReportScreenshotParticipationWidget
