/**
 * ADM-INTEGRATION-LUCCA.MODAL
 * Load data from SIRH LUCCA in order to save list
 */
import { store } from "@/index"
import { v4 as uuid } from "uuid"
import { useEffect, useState } from "react"
import { filterFetch } from "@/redux/filter.actions"
import { Filter } from "@/redux/filter.types"
import { integrationFetchLuccaOptions, integrationFetchLuccaUsers } from "@/redux/integration.actions"
import { User } from "@/redux/user.types"
import ImportModal from "./import.modal"
import LoadingModal from "./loading.modal"

interface OwnProps {
  onClose: Function
  onNext: Function
}

function AdmIntegrationLuccaModal(props: OwnProps) {
  //Loading in progress
  const [isLoading, setIsLoading] = useState(true)

  const [filtersToImport, setFiltersToImport] = useState<Filter[]>([])
  const [usersToImport, setUsersToImport] = useState<User[]>([])

  //On load fetch Lucca Users
  useEffect(() => {
    load()

    async function load() {
      //Init users array
      const filters: Filter[] = []
      const users: User[] = []

      //Load data
      const filtersResponse: any = await store.dispatch(filterFetch(false, false))
      const usersResponse: any = await store.dispatch(integrationFetchLuccaUsers())
      const optionsResponse: any = await store.dispatch(integrationFetchLuccaOptions())

      //Create item to store
      if (!usersResponse.error && !optionsResponse.error && !filtersResponse.error) {
        //Get filters
        optionsResponse.attributes.forEach((item) => {
          if (
            ["address", "birthDate", "dtContractStart", "firstName", "gender", "lastName", "mail", "culture"].indexOf(
              item.id
            ) === -1
          ) {
            const currentFilter = filtersResponse.find((x) => x.importName === item.id)

            filters.push(
              new Filter({
                id: currentFilter ? currentFilter.id : uuid(),
                importName: item.id,
                name: item.description
              })
            )
          }
        })

        //Loop on users
        usersResponse.forEach((user) => {
          const newUser = new User({
            address: user.address,
            birthDate: user.birthDate,
            companyWelcomeDate: user.dtContractStart,
            firstname: user.firstName,
            gender: user.gender ? user.gender[0].toLowerCase() : null,
            lastname: user.lastName,
            email: user.mail,
            language: user.culture.code.split("-")[0].toLowerCase()
          })

          //Edit attributes for user
          const attributes: any = {}
          filters.forEach((filter) => {
            if (filter.importName) {
              const currentUserFilter = user[filter.importName]
              if (currentUserFilter) {
                if (currentUserFilter.name) {
                  attributes[filter.name] = currentUserFilter.name
                }
              }
            }
          })

          //Update attribute
          newUser.attributes = attributes

          //Add user to array
          users.push(newUser)
        })
      }

      //Update state
      setUsersToImport(users)
      setFiltersToImport(filters)

      //End of loading
      setIsLoading(false)
    }
  }, [])

  return isLoading ? (
    <LoadingModal />
  ) : (
    <ImportModal
      notAssociatedManagers={[]}
      isFromIntegration
      filters={filtersToImport}
      users={usersToImport}
      onClose={() => props.onClose()}
      onNext={() => props.onNext()}
    />
  )
}

export default AdmIntegrationLuccaModal
