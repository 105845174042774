/***
 * PROJECT-RECIPIENTS.WIDGET
 * List of users to invite for survey
 */

import { connect } from "react-redux"
import { WithTranslation, withTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { Session } from "@/redux/_session.types"
import {
  faBars,
  faHandPointer,
  faUserCircle,
  faUserMinus,
  faEnvelope,
  faMobileAlt,
  faQrcode,
  IconDefinition,
  faExclamationTriangle,
  faUserPlus,
  faPencilAlt
} from "@fortawesome/free-solid-svg-icons"
import { store } from "@/index"
import UpgradeModal from "@/modals/upgrade.modal"
import CardContainer from "@/components/card-container"
import CardButton from "@/components/card-button"
import { projectEdit } from "@/redux/project.actions"
import { ProjectState } from "@/redux/project.types"
import Space from "@/components/space"
import SelectPopulationsModal from "@/modals/select-populations.modal"
import ListContainer from "@/components/list-container"
import { User, UserState } from "@/redux/user.types"
import { userActivate, userFetchRecipients, userGet, userGetCountSearch, userStatus } from "@/redux/user.actions"
import { STATUS_LOADED, STATUS_LOAD_ERROR, STATUS_SEARCHING } from "@/redux/_status.types"
import ListItem from "@/components/list-item"
import ListIcon from "@/components/list-icon"
import ListCell from "@/components/list-cell"
import ListButton from "@/components/list-button"
import { validateEmail } from "@/utils/validate-email.utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Chip from "@/components/chip"
import Link from "@/components/link"
import { Population } from "@/redux/population.types"
import AdmEditUserModal from "@/modals/adm-edit-user.modal"
import ConfirmButtons from "@/components/confirm-buttons"
import ProjectStepErrorsWidget from "./project-step-errors.widget"

interface StateProps extends WithTranslation {
  _session: Session
  project: ProjectState
  user: UserState
}

interface OwnProps {
  onEdit: Function
  onNext: Function
}

type Props = StateProps & OwnProps

interface UserMode {
  id: string
  icon: IconDefinition
  value?: string | null
}

//Modals
const MODAL_EDIT: string = "MODAL_EDIT" //Edit user
const MODAL_POPULATIONS: string = "MODAL_POPULATIONS" //Display list of populations to select
const MODAL_UPGRADE_SEGMENTATION: string = "MODAL_UPGRADE_SEGMENTATION" //Lock segmentation

function ProjectRecipientsWidget(props: Props) {
  const { t } = props

  //Current modal displayed
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Input for search
  const [currentSearch, setCurrentSearch] = useState<string>("")

  //Offset when changing page
  const [currentOffset, setCurrentOffset] = useState<number>(0)

  //Load users and filters when offset or search are changed
  useEffect(() => {
    load()

    //Fetch users from backend
    async function load() {
      store.dispatch(userStatus(STATUS_SEARCHING))
      const recipients: any = await store.dispatch(
        userFetchRecipients(props.project.active.id, currentSearch, 5, currentOffset)
      )
      if (recipients.error) {
        store.dispatch(userStatus(STATUS_LOAD_ERROR))
      } else {
        //Save data in store
        store.dispatch(userGet(recipients.users))
        store.dispatch(userGetCountSearch(recipients.error ? 0 : recipients.count))
        store.dispatch(userStatus(STATUS_LOADED))
      }
    }
  }, [props._session.participationMin, props.project.active.id, currentOffset, currentSearch])

  //Reset values
  function cancel() {
    store.dispatch(projectEdit("excludedUsers", []))
    store.dispatch(projectEdit("populations", []))
    store.dispatch(projectEdit("isSendToEveryone", true))
    select()
    next(false, false)
  }

  //Get attributes button for all lists
  function getAttributeButtons(user) {
    return (
      <div className="flex">
        <ListButton
          onClick={() => {
            store.dispatch(userActivate(user))
            setCurrentModal(MODAL_EDIT)
          }}
          icon={faPencilAlt}
          text={t("user_edit")}
        />
      </div>
    )
  }

  //Get mode d'invitation
  function getMode(user: User) {
    let mode: UserMode | null = null
    let error: string | null = null

    if (props.project.active.diffusionMode.sms && user.phone) {
      mode = { id: "sms", icon: faMobileAlt, value: user.phone }
    } else if (props.project.active.diffusionMode.email && validateEmail(user.email!)) {
      mode = { id: "email", icon: faEnvelope, value: user.email }
    } else if (props.project.active.diffusionMode.qrcode) {
      mode = { id: "qrcode", icon: faQrcode }
    }

    if (!mode) {
      if (props.project.active.diffusionMode.email && (!user.email || !validateEmail(user.email))) {
        error = "email"
      }
    }

    return (
      <div
        style={{
          fontSize: "12px"
        }}>
        {mode ? (
          <div className="grey-t">
            <FontAwesomeIcon icon={mode.icon} />
            &nbsp;
            {t("user_mode_" + mode.id, { value: mode.value })}
          </div>
        ) : (
          <div className="red-t">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            &nbsp;
            <b>{t("user_mode_error")}</b>
            {error && <span>&nbsp; ({t("user_mode_error_" + error)})</span>}
          </div>
        )}
      </div>
    )
  }

  //Check if recipient is invited
  function isRecipientExcluded(userId) {
    return props.project.active.excludedUsers.indexOf(userId) > -1
  }

  async function next(isVisited, isConfirmed) {
    props.onNext(isVisited, isConfirmed)
  }

  async function select() {
    props.onEdit()
  }

  //Select recipient
  //Add to excluded users list or remove it if found
  function selectRecipient(userId) {
    if (isRecipientExcluded(userId)) {
      store.dispatch(
        projectEdit(
          "excludedUsers",
          props.project.active.excludedUsers.filter((x) => x !== userId)
        )
      )
    } else {
      store.dispatch(projectEdit("excludedUsers", props.project.active.excludedUsers.concat([userId])))
    }
    select()
  }

  //Search user
  function searchUser(search: string) {
    setCurrentSearch(search)
  }

  //Change page and fetch users again
  function setPage(offset: number) {
    setCurrentOffset(offset)
  }

  //Click on card
  function selectCard(isSendToEveryone: boolean) {
    if (isSendToEveryone) {
      store.dispatch(projectEdit("isSendToEveryone", true))
      select()
    } else {
      if (!props._session.modules.segment) {
        setCurrentModal(MODAL_UPGRADE_SEGMENTATION)
      } else if (!props.project.active.populations.length) {
        setCurrentModal(MODAL_POPULATIONS)
      } else {
        store.dispatch(projectEdit("isSendToEveryone", false))
        select()
      }
    }
  }

  //Remove population
  function removePopulation(population: Population) {
    const populations = props.project.active.populations.filter((x) => x.id !== population.id)
    store.dispatch(projectEdit("populations", populations))
    selectCard(populations.length ? false : true)
  }

  //Select populations
  function selectPopulations(populations) {
    store.dispatch(projectEdit("populations", populations))
    selectCard(false)
  }

  return (
    <div>
      {currentModal === MODAL_EDIT && (
        <AdmEditUserModal
          userToUpdate={props.user.active}
          onClose={() => setCurrentModal(null)}
          onNext={() => {
            setCurrentModal(null)
            select()
          }}
        />
      )}

      {currentModal === MODAL_POPULATIONS && (
        <SelectPopulationsModal
          onClose={() => setCurrentModal(null)}
          isSurveyFiltersIncluded
          onNext={(populations) => selectPopulations(populations)}
          selectedPopulations={props.project.active.populations}
        />
      )}

      {currentModal === MODAL_UPGRADE_SEGMENTATION && (
        <UpgradeModal feature="segment" onClose={() => setCurrentModal(null)} />
      )}

      <ListItem>
        <div className="setup-card-help">
          <p>{t("project_recipients_help")}</p>
          <ul className="grey-t setup-card-help-content">
            <li>{t("project_recipients_help_1")}</li>
            <li>{t("project_recipients_help_2")}</li>
          </ul>
        </div>
      </ListItem>

      <CardContainer>
        <CardButton
          icon={faBars}
          isActive={props.project.active.isSendToEveryone}
          onClick={() => selectCard(true)}
          title={t("project_invite_all")}></CardButton>

        <CardButton
          icon={faHandPointer}
          isLock={!props._session.modules.segment}
          isActive={!props.project.active.isSendToEveryone}
          onClick={() => selectCard(false)}
          title={t("project_invite_list")}></CardButton>
      </CardContainer>

      <div className="height-10" />

      {!props.project.active.isSendToEveryone && (
        <ListItem>
          <div>
            <h3>{t("project_populations_invited")}</h3>
            <div className="flex flex-wrap">
              {props.project.active.populations.map((population, i) => (
                <Chip
                  key={i}
                  onDelete={() => removePopulation(population)}
                  onClick={() => setCurrentModal(MODAL_POPULATIONS)}
                  isWithMargin>
                  {population.filterName} : {population.name}
                </Chip>
              ))}
              <div style={{ margin: "6px 8px" }}>
                <Link isWithoutMargin onClick={() => setCurrentModal(MODAL_POPULATIONS)}>
                  {t("populations_select")}
                </Link>
              </div>
            </div>
          </div>
        </ListItem>
      )}

      <ListContainer
        countLimit={5}
        title={t("user_recipients_title")}
        countSearch={props.user.countSearch}
        isFooterActive
        onSearch={searchUser}
        onSearchStart={() => store.dispatch(userStatus(STATUS_SEARCHING))}
        onSetPage={setPage}
        status={props.user.status}>
        {props.user.list.map((user: User) => (
          <ListItem key={user.id}>
            <ListIcon fallbackIcon={faUserCircle} image={user.imageUrl} />

            <ListCell text={user.username} width={200} />

            {getMode(user)}

            <Space />

            {getAttributeButtons(user)}

            <ListButton
              onClick={() => selectRecipient(user.id)}
              text={t("project_recipient_remove")}
              icon={faUserMinus}
            />
          </ListItem>
        ))}
      </ListContainer>

      {props.user.listExcluded.length > 0 && (
        <ListContainer title={t("project_users_excluded")}>
          {props.user.listExcluded.map((user) => (
            <ListItem key={user.id}>
              <ListIcon fallbackIcon={faUserCircle} image={user.imageUrl} />

              <ListCell text={user.username} width={200} />

              <Space />

              {getAttributeButtons(user)}

              <ListButton
                onClick={() => selectRecipient(user.id)}
                text={t("project_recipient_add")}
                isPrimary
                icon={faUserPlus}
              />
            </ListItem>
          ))}

          <div className="height-40" />
        </ListContainer>
      )}

      <ProjectStepErrorsWidget alert={props.project.active.alerts.recipients} step="recipients" />

      <ConfirmButtons
        onCancel={() => cancel()}
        onConfirm={() => next(true, true)}
        onConfirmLater={() => next(true, false)}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  project: state.project,
  user: state.user
})

export default connect(mapStateToProps)(withTranslation()(ProjectRecipientsWidget))
